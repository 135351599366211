import Meta from '@root/common/utils/Meta';
import articleConfig from '@root/modules/article/config/article.config';
import getArticleCustomStyles from '@root/modules/article/utils/getArticleCustomStyles';

import { GetChannelConfigFunction } from '@root/libs/init-channel/types';
import { Article } from '@root/modules/article/types/article.type';
import { Meta as ArticleMeta, CommentsArticleData } from '@root/modules/article/types/article';

import { getCustomCropsPerRatio, getFocuspointQueryString } from '@root/libs/api-provider/utils/pictureCustomCrops';
import { getEnv } from '@root/common/utils/getEnv';

function getArticleMeta({
  $channelConfig,
  article,
  path,
}: {
  $channelConfig: GetChannelConfigFunction;
  article: Article | CommentsArticleData | null;
  path: string;
}) {
  const meta = new Meta($channelConfig('meta').base, path);

  if (article) {
    const { id, publishAt, primaryCategory, tags, content, contentValues } = article;
    const cssText = getArticleCustomStyles(article);
    const paywallEnabled = content.paywall.enabled || false;
    const authors = article.authors;
    const seoFields = article.seoFields;

    meta.title = article.seoFields.seoTitle || article.content.title.text || '';
    meta.ogTitle = article.content.title.text || '';
    meta.description = article.seoFields.seoDescription || article.content.lead?.text || '';
    meta.ogDescription = article.content.lead?.text || '';
    meta.type = 'article';

    if (tags.items?.length) {
      const keywords = tags.items.map((tag) => tag.name).join(', ');
      meta.keywords = keywords;
    }

    if (article.metaImage) {
      const picture = article.metaImage;
      const customCrops = picture?.attributes?.cropperData?.customCrops;
      const customCropsPerRatio = getCustomCropsPerRatio(customCrops);
      const focuspoint = picture?.attributes?.cropperData?.focuspoint;
      const focusPointString = getFocuspointQueryString(focuspoint);

      const url = getEnv('mediaApiCropper');
      const src = `${url}/${picture.id}.jpg?w=1200&h=800${focusPointString}${customCropsPerRatio['16:9']}`;

      meta.imageData = {
        src,
        ...articleConfig.meta.imageData,
      };
    }

    meta.createArticleMeta({
      id,
      publishAt,
      primaryCategory: { ...primaryCategory, slug: primaryCategory.slug || '' },
      lang: $channelConfig('settings').lang,
      paywallEnabled,
      contentValues,
      authors,
      seoFields,
    });

    if (cssText) {
      meta.style = [{ cssText, hid: 'custom-article' }];
    }
  }

  return meta;
}

function replaceArticleMeta(newMeta: ArticleMeta) {
  const htmlMetaData = document.getElementsByTagName('meta');
  document.title = newMeta.title;

  for (const meta of newMeta.meta) {
    for (const htmlMeta of htmlMetaData) {
      if (htmlMeta.getAttribute('data-hid') === meta.hid) {
        htmlMeta.setAttribute('content', meta.content);
      }
    }
  }
}

export { getArticleMeta, replaceArticleMeta };
